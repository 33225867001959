<template>
  <section class="content">
    <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="user_id：">
                    <el-input v-model="user_id" placeholder="请输入user_id"></el-input>
                </el-form-item>
                <el-form-item label="期数：">
                    <el-input v-model="stage_num" placeholder="请输入期数"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
        </div>
    <el-table
        v-loading="loading"
        :data="list"
        border>
        <el-table-column label="id" prop="id" width="50"></el-table-column>
        <el-table-column label="user_id (user_key)" width="200">
          <template slot-scope="scope">
            {{scope.row.user_id}}({{scope.row.user_key}})
          </template>
</el-table-column>
<el-table-column label="期数" prop="community_id"></el-table-column>
<el-table-column label="订单数" prop="order_num"></el-table-column>
<el-table-column label="状态">
    <template slot-scope="scope">
    {{scope.row.status | status}}
  </template>
</el-table-column>
<el-table-column label="操作" width="200">
    <template slot-scope="scope">
    <el-button v-if="scope.row.status == 1" type="danger" size="mini" @click="updata(scope.row.id,scope.row.status)" >拉黑</el-button>
    <el-button v-else type="primary" size="mini" @click="updata(scope.row.id,scope.row.status)" >解除</el-button>
  </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'associationUser',
        data() {
            return {
                list: [],

                user_id: '',
                stage_num: '',

                loading: true,
                page: 1,
                pagesize: 10,
                total: 10

            }
        },
        filters: {
            status(val) {
                let name
                switch (Number(val)) {
                    case 1:
                        name = '正常'
                        break;
                    case 2:
                        name = '封禁'
                        break;
                    default:
                        name = ""
                        break;
                }
                return name
            },
        },
        mounted() {
            this.getAssociationUser()
        },
        methods: {
            getAssociationUser(page, size) {
                api.getAssociationUser({
                    user_id: this.user_id,
                    stage_num: this.stage_num,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.list = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getAssociationUser()
            },
            currentChange(val) {
                this.page = val
                this.getAssociationUser()
            },
            seach(page, size) {
                this.getAssociationUser(page, size)
            },
            updata(id, status) {
                let tip = status == 1 ? '拉黑后不可再继续绑定订单' : '解除后用户可继续绑定订单'
                let title = status == 1 ? '确定要拉黑TA吗' : '确定要解除TA吗'
                this.$confirm(tip + '，你还要继续吗？', title, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.updataStatus(id, status)
                }).catch(() => {})
            },
            updataStatus(id, status) {
                api.updataAssociationUser({
                    id: id,
                    status: status == 1 ? 2 : 1
                }, res => {
                    this.$message({
                        type: 'success',
                        message: status == 1 ? '拉黑成功' : '解除成功'
                    });
                    this.getAssociationUser()
                })
            }
        }
    }
</script>
<style lang="scss">
    .el-form-item {
        text-align: left;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
</style>